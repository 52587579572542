import React, { useEffect, useState } from "react";
import "../../style/AlgoCard.css";
import algo1 from "../../assets/algo1.png";
import { useNavigate } from "react-router-dom";
import AlgoDetail from "./AlgoDetail";
import { createAsub, updateAsub } from "../../services/userService";
import RunAlgo from "./RunAlgo";

const AlgoCard = ({
  ddpi,
  ddpiLink,
  name,
  author,
  capital,
  initialStatus,
  risk,
  instruments,
  tags,
  dd,
  marginRequired,
  entryTime,
  exitTime,
  description,
  sd,
  algoId,
  asubId,
}) => {
  console.log(initialStatus)
  const [showPopup, setShowPopup] = useState(false);
  const [status, setStatus] = useState(initialStatus);
  const [processing, setProcessing] = useState(false); // To prevent overlapping actions


  const formatNumber = (num) => {
    if (num >= 10000000) {
      // Convert to crores
      return (num / 10000000).toFixed(2) + "cr";
    } else if (num >= 100000) {
      // Convert to lacs
      return (num / 100000).toFixed(2) + "lac";
    } else if (num >= 1000) {
      // Convert to thousands
      return (num / 1000).toFixed(0) + "k";
    } else {
      // Return as is if less than 1000
      return num.toString();
    }
  };

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/plans");
  };

  const updateStatus = (newStatus) => {
    setStatus(newStatus);
  };

  const handleNavigateKYC = () => {
    const number = localStorage.getItem("number");
    window.open(
      "https://optionbase-kyc.enrichmoney.in/register?number=" + number,
      "_blank"
    );
  };

  const handleNavigateLogin = () => {
    window.open(
      "https://orca.enrichmoney.in/partner/login?partner=OPTIONBASE",
      "_blank"
    );
  };

  const handleTogglePopup = () => {
    setShowPopup(!showPopup);
  };


  const handlePause = async () => {
    if (processing) return;
    setProcessing(true);
    const token = localStorage.getItem("token");
    try {
      await updateAsub(token, { asub_id: asubId, status: "Paused" });
      setStatus("Paused");
    } catch (err) {
      console.error("Error pausing subscription:", err);
    } finally {
      setProcessing(false);
    }
  };

  const handleResume = async () => {
    if (processing) return; // Prevent overlapping calls
    setProcessing(true);
    const token = localStorage.getItem("token");
    try {
      await updateAsub(token, { asub_id: asubId, status: "Live" });
      setStatus("Live");
    } catch (err) {
      console.error("Error resuming subscription:", err);
    } finally {
      setProcessing(false);
    }
    
  };

  const handleDdpi= () => {
    if(ddpiLink != null && ddpi != ""){
      window.open(ddpiLink, "_blank");
    }
  };

  

  useEffect(() => {
    if (status !== initialStatus) {
      setStatus(initialStatus);
    }
  }, [initialStatus]);

  // Determine button class based on status
  let buttonClass = "subscribe"; // Default class
  if (ddpi === "false") {
    buttonClass = "subscribe";
  } else if (status === "Live") {
    buttonClass = "live2";
  } else if (status === "Open") {
    buttonClass = "open";
  } else if (status === "Paused") {
    buttonClass = "paused";
  }

  // Determine button label based on status
  let buttonLabel = "Subscribe"; // Default label
  if (status === "Live") {
    if (ddpi == "false"){
      if(ddpiLink != null && ddpiLink != ""){
        buttonLabel = "Enable DDPI"
      }else{
        buttonLabel = "DDPI Processing"
      }
    }else{
      buttonLabel = "Pause";
    }
    
  } else if (status === "Open") {
    if (ddpi == "false"){
      if(ddpiLink != null && ddpiLink != ""){
        buttonLabel = "Enable DDPI"
      }else{
        buttonLabel = "DDPI Processing"
      }
    }else{
      buttonLabel = "Review & Run";
    }
    
  } else if (status === "Paused") {
    if (ddpi == "false"){
      if(ddpiLink != null && ddpiLink != ""){
        buttonLabel = "Enable DDPI"
      }else{
        buttonLabel = "DDPI Processing"
      }
    }else{
      buttonLabel = "Resume";
    }
  } else if (status === "KYC") {
    buttonLabel = "Complete KYC";
  } else if (status === "Login") {
    buttonLabel = "Login";
  }

  return (
    <div className="algo-card" onClick={handleTogglePopup}>
      <div className="algo-image">
        <img src={algo1} alt={name} />
      </div>
      <div className="algo-details">
        <h4>{name}</h4>
        <p className="author">By {author}</p>
        <div className="card-bottom">
          <p className="current-bid">Min capital: ₹{formatNumber(capital)}</p>

          <button
            className={`place-bid ${buttonClass}`}
            onClick={(e) => {
              e.preventDefault(); // Prevent default behavior
              e.stopPropagation();

              if (status === "Live") {
                if (ddpi === "false") {
                  handleDdpi();
                }else{
                  handlePause();
                }  
                setShowPopup(false);
              } else if (status === "Open") {
                if (ddpi === "false") {
                  handleDdpi();
                }else{
                } 
                setShowPopup(true)
              } else if (status === "Paused") {
                if (ddpi === "false") {
                  handleDdpi();
                }else{
                  handleResume();
                }      
                setShowPopup(false);
              } else if (status === "KYC") {
                handleNavigateKYC();
                setShowPopup(false);
              } else if (status === "Login") {
                handleNavigateLogin();
                setShowPopup(false);
              } else {
                handleNavigate();
                setShowPopup(false);
              }

              
              
            }}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
      {showPopup && (
        <AlgoDetail
          onClose={handleTogglePopup}
          updateStatus={updateStatus}
          ddpi={ddpi}
          ddpiLink={ddpiLink}
          name={name}
          author={author}
          capital={capital}
          initialStatus={status}
          risk={risk}
          instruments={instruments}
          tags={tags}
          dd={dd}
          marginRequired={marginRequired}
          entryTime={entryTime}
          exitTime={exitTime}
          description={description}
          sd={sd}
          algoId={algoId}
          asubId={asubId}
        />
      )}
      
    </div>
  );
};

export default AlgoCard;
