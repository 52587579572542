import React, { useEffect, useState } from 'react';
import '../style/PurchasePage.css';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import globeImage from '../assets/PurchasePage_Globe.png';
import { createTransaction, getPlan } from '../services/userService';
import { redirect, useNavigate } from 'react-router-dom';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";



const PurchasePage = () => {
    const [plans, setPlans] = useState([]);
    const [token, setToken] = useState(null);
    const [activeOption, setActiveOption] = useState('billing-yearly'); // Default active option
    const [baseMonthlyPrice, setBaseMonthlyPrice] = useState(null);
    const [baseMarginRequired, setBaseMarginRequired] = useState(null);
    const [baseYearlyPrice, setBaseYearlyPrice] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null); 
    const [isLoading, setIsLoading] = useState(true);
    const { error, isLoading2, Razorpay } = useRazorpay();
    const [paymentInProgress, setPaymentInProgress] = useState(false); 

    const handleOptionClick = (option) => {
        setActiveOption(option);
    };

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    const kycStatus = localStorage.getItem('kyc_status');

    const loginTime = localStorage.getItem('loginTime');
    const loginDate = new Date(loginTime);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate - loginDate;

    // Step 5: Convert milliseconds to minutes
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);


    if (!token || (kycStatus === "false" && diffInMinutes > 30)) {
        navigate('/');
    }
  }, [navigate]);



    useEffect(() => {
    const token = localStorage.getItem('token');
    setToken(token);
    const fetchPlans = async () => {
        setIsLoading(true);
        if(token){
            const response = await getPlan(token, {});
            const sortedPlans = response.data.sort((a, b) => a.Sequence - b.Sequence);
            setPlans(response.data);
            const planWithSequenceOne = response.data.find(plan => plan.Sequence === 1);

            // Extract MonthlyPrice if the plan exists
            if (planWithSequenceOne) {
                setBaseMonthlyPrice(planWithSequenceOne.MonthlyPrice);
                setBaseYearlyPrice(planWithSequenceOne.YearlyPrice);
                setBaseMarginRequired(planWithSequenceOne.MarginRequired)
                setSelectedPlan(planWithSequenceOne);
            }
        }
        setIsLoading(false); 
    };
  
    fetchPlans();
  }, [token]); 
   

    

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    const purchase = async () => {
        setPaymentInProgress(true);
        try{
            const profile_id = localStorage.getItem('profile_id');
            const amount = activeOption === "billing-yearly"?(Math.round(selectedPlan.YearlyPrice)):(Math.round(selectedPlan.MonthlyPrice));
            // const amount = 1;
            const response = await createTransaction({"profile_id":profile_id,"amount":amount,"status":false,"plan_id":selectedPlan.PlanID,"access_months":activeOption === "billing-yearly"?12:3});
            
            const options = {
                key: "rzp_live_TjEtKnRORnJYIS",
                amount: (amount*100), // Amount in paise
                currency: "INR",
                name: "TradeInsights",
                description: "Automated Investing by SEBI RAs & Quant Traders",
                order_id: response.data.order_id, // Generate order_id on server
                prefill: {
                contact: response.data.mobile_number,
                },
                redirect:true,
                callback_url: "https://app.tradeinsights.co.in/dashboard",
                theme: {
                color: "#3399cc",
                },
            };
        
            const razorpayInstance = new Razorpay(options);
            razorpayInstance.open();
            setPaymentInProgress(false);

            razorpayInstance.on('payment.success', () => {
                setPaymentInProgress(false);
            });
    
            razorpayInstance.on('payment.error', () => {
                setPaymentInProgress(false);
            });
        }catch(e){
            setPaymentInProgress(false);
        }
        // console.log(response.data.data.instrumentResponse.redirectInfo.url);
        // window.open(response.data.data.instrumentResponse.redirectInfo.url, "_blank"); 
    };

    return (
        <>
            {isLoading ? (
                <div className="loader-container">
                    <div className="blue-loader"></div> {/* CSS Loader */}
                </div>
            ) : (
                <>
                <div className='plans-header'>
                    <h1 className="plans-heading">Affordable, systematic trading Algos</h1>
                    <p className="plans-subheading">Plans based on your Investment amount</p>
                </div>
                <div className="plans-container">
                    <div className="plans-left">
                        <img src={globeImage} alt="Globe" className="globe-image" />
                        <div className="plans-features">
                            <ul>
                                <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />All Powerful Algos</li>
                                <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />New Algos every month for free</li>
                                <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Auto money management</li>
                                <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Free Algo maintenance</li>
                                <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Dedicated Support</li>
                                <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Fully automated systems & dashboard</li>
                                <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Zero broker account opening fee</li>
                            </ul>
                        </div>
                    </div>
                    <div className="plans-content">
                        <div className="pricing-container">
                            {selectedPlan && selectedPlan.Name ? (
                            <div className="billing-box">
                                <div className={activeOption==="billing-monthly"?"billing-option billing-yearly":"billing-option billing-monthly"} onClick={() => handleOptionClick('billing-monthly')}>
                                    <div>Billed <span>Quarterly</span></div>
                                    <div className='price-div'>₹{(Math.round(selectedPlan.MonthlyPrice/3))}<span>/month</span></div>
                                </div>
                                <div className={activeOption==="billing-yearly"?"billing-option billing-yearly":"billing-option billing-monthly"} onClick={() => handleOptionClick('billing-yearly')}>
                                    <div>Billed <span>Yearly</span> <span className="b-discount">60%+ OFF</span></div>
                                    <div className='price-div'>₹{(Math.round(selectedPlan.YearlyPrice/12))}<span>/month</span></div>
                                </div>
                            </div>):null
                            }

                            <div className="features-and-plans-container">
                                <div className="features-list">
                                    <h3>All Features</h3>
                                    <div className="checkmarks-container">
                                        <IoMdCheckmarkCircleOutline />
                                        <IoMdCheckmarkCircleOutline />
                                        <IoMdCheckmarkCircleOutline />
                                        <IoMdCheckmarkCircleOutline />
                                        <IoMdCheckmarkCircleOutline />
                                        <IoMdCheckmarkCircleOutline />
                                        <IoMdCheckmarkCircleOutline />
                                    </div>
                                </div>

                                {selectedPlan && selectedPlan.Name ? (

                                <div className="plans">
                                    {plans.map((plan, index) => (
                                        <div
                                            key={index}
                                            className={`plan-option ${selectedPlan.Name === plan.Name ? 'selected' : ''}`}
                                            onClick={() => handlePlanSelect(plan)}
                                        >
                                            <span className="icon">
                                                <IoMdCheckmarkCircle />
                                            </span>
                                            <div className="details">
                                                <p>{plan.Name}</p>
                                                <p className='savings'>{activeOption === "billing-monthly"? Math.round(((baseMonthlyPrice*(plan.MarginRequired/baseMarginRequired) - plan.MonthlyPrice)/(baseMonthlyPrice*(plan.MarginRequired/baseMarginRequired)))*100):Math.round(((baseMonthlyPrice*4*(plan.MarginRequired/baseMarginRequired) - plan.YearlyPrice)/(baseMonthlyPrice*4*(plan.MarginRequired/baseMarginRequired)))*100)}% Savings</p>

                                            </div>
                                            <div className={plan.price === "Contact us"?"price price2":"price"}>₹{activeOption === "billing-yearly"?(Math.round(plan.YearlyPrice/(30*12))):(Math.round(plan.MonthlyPrice/(30*3)))}<span>/day</span></div>
                                        </div>
                                    ))}
                                    <div
                                            key={100}
                                            className={`plan-option ${selectedPlan.Name === "Custom (₹10lac +)" ? 'selected' : ''}`}
                                            onClick={() => handlePlanSelect({
                                                Name: 'Custom (₹10lac +)',
                                                
                                            })}
                                        >
                                            <span className="icon">
                                                <IoMdCheckmarkCircle />
                                            </span>
                                            <div className="details">
                                                <p>Custom (₹10lac +)</p>
                                                <p className="savings">-% Savings</p>
                                            </div>
                                            <div className="price price2">{activeOption === "billing-yearly"?"Contact us":"Contact us"}<span>/day</span></div>
                                        </div>
                                </div>):null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {selectedPlan && selectedPlan.Name ? (

                <div className="bottom-section">
                    <div className="investment-details">
                        <p>{selectedPlan.Name}</p>
                        <span className='savings'>{activeOption === "billing-monthly"? Math.round(((baseMonthlyPrice*(selectedPlan.MarginRequired/baseMarginRequired) - selectedPlan.MonthlyPrice)/(baseMonthlyPrice*(selectedPlan.MarginRequired/baseMarginRequired)))*100):Math.round(((baseMonthlyPrice*12*(selectedPlan.MarginRequired/baseMarginRequired) - selectedPlan.YearlyPrice)/(baseMonthlyPrice*12*(selectedPlan.MarginRequired/baseMarginRequired)))*100)}% Savings</span>
                    </div>
                    <div className="price-and-button">
                        <h3>₹{activeOption === "billing-yearly"?(Math.round(selectedPlan.YearlyPrice)):(Math.round(selectedPlan.MonthlyPrice))}<span>{activeOption === "billing-yearly"?"/year":"/quarter"}</span></h3>
                        <button className="subscribe-button" onClick={purchase} disabled={paymentInProgress}>Subscribe</button>
                    </div>
                </div>):null
                }
                </>
            )}
        </>
    );
};

export default PurchasePage;
