import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const LoginCompletion = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userId = urlParams.get('userId');
    const authorization = urlParams.get('x-authorization');

    if (userId && authorization) {
      const url = 'https://tradeinsights.co.in/v1/api/users/getAccess';
      const data = {
        user_id: userId,
        authorization: authorization,
      };

      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'rata378',
      };

      fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          console.log('Success:', data);
          localStorage.setItem('authorization', authorization);
          localStorage.setItem('user_id', userId);
          localStorage.setItem('kyc_status', true);
          const addHours = (hours) => {
            const now = new Date();
            now.setHours(now.getHours() + hours);
            return now;
          };
          
          const newDate = addHours(24).toISOString();
          localStorage.setItem('auth_expiry', newDate);
          navigate("/dashboard")
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  return (
    <div style={styles.body}>
      <div style={styles.messageBox}>
        <h1 style={styles.title}>Your KYC is Submitted!</h1>
        <p style={styles.text}>
          Your Trading account will be activated in the next 24-48 hours.
        </p>
        <p style={styles.text}>Please close this window.</p>
        <button style={styles.button} onClick={() => window.close()}>
          Close Window
        </button>
      </div>
    </div>
  );
};

const styles = {
  body: {
    fontFamily: "'Arial', sans-serif",
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #ff9a9e, #fad0c4)',
    color: '#333',
  },
  messageBox: {
    background: '#fff',
    borderRadius: '10px',
    padding: '20px 30px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '400px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '15px',
    color: '#333',
  },
  text: {
    fontSize: '16px',
    marginBottom: '10px',
    color: '#555',
  },
  button: {
    backgroundColor: '#ff6b6b',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#ee5253',
  },
};

export default LoginCompletion;
