import { apiRequest1, apiRequest2, apiRequest3, apiRequest4, apiRequest5, apiRequest6, apiRequest7 } from '../api/apiService';

export const sendOtp = (data) => apiRequest1('users/sendOtp', 'POST', data);
export const verifyOtp = (data) => apiRequest1('users/verifyOtp', 'POST', data);
export const getUserById = (token, data) => apiRequest3('users/getUserById', token, 'POST', data);
export const updateUser = (token, data) => apiRequest3('users/update', token, 'POST', data);
export const getAccess = (data) => apiRequest1('users/getAccess', 'POST', data);
export const kycPostback = (data) => apiRequest2('users/kycPostback', 'POST', data);
export const registerId = (data) => apiRequest4('users/registerId', 'POST', data);
export const createAlgo = (token, data) => apiRequest3('algos/create', token, 'POST', data);
export const updateAlgo = (token, data) => apiRequest3('algos/update', token, 'POST', data);
export const getAlgos = (token, data) => apiRequest3('algos/getAlgos', token, 'POST', data);
export const getSubscribedAlgos = (token, data) => apiRequest3('algos/getSubscribedAlgos', token, 'POST', data);
export const getUnsubscribedAlgos = (token, data) => apiRequest3('algos/getUnsubscribedAlgos', token, 'POST', data);
export const createPlan = (token, data) => apiRequest3('plans/create', token, 'POST', data);
export const updatePlan = (token, data) => apiRequest3('plans/update', token, 'POST', data);
export const getPlan = (token, data) => apiRequest3('plans/getPlans', token, 'POST', data);
export const getPlanByProfileID = (token, data) => apiRequest3('plans/getPlanByProfileID', token, 'POST', data);
export const createSubscription = (token, data) => apiRequest3('subscriptions/create', token, 'POST', data);
export const updateSubscription = (token, data) => apiRequest3('subscriptions/update', token, 'POST', data);
export const createTransaction = (data) => apiRequest2('transactions/create', 'POST', data);
export const tranCallback = (data) => apiRequest2('transactions/tranCallback', 'POST', data);
export const getFunds = (userId, authorization, data) => apiRequest5('client-handler-api/v1/funds/sublimits',userId,authorization,'POST',data)
export const getPositions = (userId, authorization, data) => apiRequest5('client-holdings-api/v1/positions',userId,authorization,'POST',data)
export const getHoldings = (userId, authorization, data) => apiRequest5('client-holdings-api/v1/holdings',userId,authorization,'POST',data)
export const ddpiStatus = (userId, authorization) => apiRequest5('ddpi-manager-api/v1/ddpi/status',userId,authorization,'GET')
export const initDdpi = (userId, authorization) => apiRequest5('ddpi-manager-api/v1/ddpi/initiate',userId,authorization,'GET')
export const signDdpi = (userId, authorization, data) => apiRequest5('ddpi-manager-api/v1/ddpi/eSign',userId,authorization,'POST',data)
export const getAsubs = (token, data) => apiRequest3('asubs/findByProfileId', token, 'POST', data);
export const createAsub = (token, data) => apiRequest3('asubs/create', token, 'POST', data);
export const updateAsub = (token, data) => apiRequest3('asubs/update', token, 'POST', data);
export const getOptionbaseDetails = (data) => apiRequest6('users/getUserByNumber', 'POST', data);
export const getPnl = (data) => apiRequest6('users/orders/getAlgoPnl', 'POST', data);
export const saveDdpi = (data) => apiRequest6('users/registerDdpi', 'POST', data);
export const sendMessageW = (data) => apiRequest7('messages/create', 'POST', data);
export const createRawUser = (data) => apiRequest1('users/createRawUser', 'POST', data);