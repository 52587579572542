import { buildApiUrl, buildApiUrl2, buildApiUrl3 } from './apiConfig';

export const apiRequest1 = async (endpoint, method = 'POST', data = null) => {
  const url = buildApiUrl(endpoint);

  const options = {
    method,
    headers: {
     "Content-Type": "application/json",
      "Authorization": "rata378"
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    console.log(response);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const apiRequest2 = async (endpoint, method = 'POST', data = null) => {
  const url = buildApiUrl(endpoint);

  const options = {
    method,
    headers: {
      "Content-Type": "application/json"
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    console.log(response);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const apiRequest3 = async (endpoint, token, method = 'POST', data = null) => {
  const url = buildApiUrl(endpoint);

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const apiRequest4 = async (endpoint, method = 'POST', data = null) => {
  const url = buildApiUrl(endpoint);

  const options = {
    method,
    headers: {
     "Content-Type": "application/json",
      "Authorization": "fvlnfkjvfosbfjbnfbfnblkj87742694296594298t5698249"
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    console.log(response);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const apiRequest5 = async (endpoint,userId, authorization, method = 'POST', data = null) => {
  const url = buildApiUrl2(endpoint);

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "user-Id":userId,
      "Authorization": authorization
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const apiRequest6 = async (endpoint, method = 'POST', data = null) => {
  const url = buildApiUrl3(endpoint);

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `rata245`
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const apiRequest7 = async (endpoint, method = 'POST', data = null) => {
  const url = buildApiUrl(endpoint);

  const options = {
    method,
    headers: {
     "Content-Type": "application/json",
      "Authorization": "hkbdvfvbfvfkw48548726564299429r98429r84298dfijfkhjvbjfebvhfe"
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    console.log(response);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};


