// src/components/Header.js
import React, {useState, useRef} from 'react';
import '../../style/Header.css';
import logo2 from '../../assets/logo2.png';
import tlogo from '../../assets/tlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import addMoney from '../../assets/add_money.png';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';


const Header = ({ mode, userId, authorization ,connect, availableBalance}) => {
  const [showLogout, setShowLogout] = useState(false);
  const buttonRef = useRef(null);
  const handleAddMoney= () => {
    window.open("https://orca.enrichmoney.in/partners/payin?partner=OPTIONBASE&segment=1&encodedUserDetails="+btoa(authorization), "_blank");
  };
  const handleWithdrawMoney= () => {
    window.open("https://orca.enrichmoney.in/partners/payout?partner=OPTIONBASE&segment=1&encodedUserDetails="+btoa(authorization), "_blank");
  };
  const handleButtonClick = () => {
    setShowLogout((prev) => !prev); // Toggle the visibility of the logout option
  };
  const navigate = useNavigate();

  const logout = async (event) => {
    event.preventDefault();
    try {
        localStorage.removeItem('token');
        localStorage.removeItem('profile_id');
        localStorage.removeItem('authorization');
        localStorage.removeItem('user_id');
        localStorage.removeItem('kyc_status');
        localStorage.removeItem('auth_expiry');
        localStorage.removeItem('number');
        localStorage.removeItem('loginTime');
        navigate('/');
    
    } catch (error) {
      
    }
  };

  const Dropdown = () => {
    if (!showLogout || !buttonRef.current) return null;

    // Get the button's position for proper alignment
    const buttonRect = buttonRef.current.getBoundingClientRect();

    return createPortal(
      <ul
        style={{
          position: 'absolute',
          top: `${buttonRect.bottom}px`,
          left: `${buttonRect.left}px`,
          backgroundColor: 'white',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          listStyle: 'none',
          padding: '8px 0',
          margin: '0',
          zIndex: 1000,
          width: '200px',
          borderRadius: '4px',
        }}
        className="dropdown-menu"
      >
        <li
          style={{
            padding: '8px 16px',
            cursor: 'pointer',
          }}
          onClick={(e) => logout(e)}
        >
          Logout
        </li>
        <li
          style={{
            padding: '8px 16px',
            cursor: 'pointer',
          }}
          onClick={(e) => handleWithdrawMoney()}
        >
          Withdraw Money
        </li>
      </ul>,
      document.body // Render dropdown at the root of the document
    );
  };


  return (
  <header className="header">
    <div className="logo">
        <img src={logo2} alt="Trade Insights Logo" className="logo-image" />
        <img src={tlogo} alt="Trade Insights" className="logo-image-title" />
    </div>
    <div className="actions">
      { mode === "Live" && connect==="false" ?
      <button className="balance header-btn">
        <div className='balance-symbol'>
            <FontAwesomeIcon icon={faIndianRupeeSign} color='#4318FF' width={10} />
        </div>
        
        <div className='balance-text'>
            <span className='balance-text1'>Available Balance</span>
            <span className='balance-text2'>₹{availableBalance}</span>
        </div>
      </button>:null
      }
      { mode === "Live" && connect==="false" ?
      <button className="add-money header-btn" onClick={handleAddMoney}>
        <img alt="Add money" src={addMoney} />
        <span>Add money</span>
      </button>:null
      }
      <div className="header-button-container" style={{ position: 'relative', overflow:'visible' }}>
        <button className="kyc-verified header-btn" onClick={handleButtonClick} ref={buttonRef}>
          <span>{mode ==="Live"?'KYC Verified':'KYC Pending'}</span>
          <div></div>
        </button>
        <Dropdown />
      </div>
    </div>
  </header>
  )
};

export default Header;
