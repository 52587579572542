import React, { useEffect, useState } from 'react';
import AlgoCard from './AlgoCard';
import '../../style/AlgosList.css';
import { getAlgos, getAsubs } from '../../services/userService';
import { useInvestment } from '../../context/InvestmentContext';

const AlgosList = ({ mode, userId, authorization, token, connect, ddpi, ddpiLink }) => {
  const [algos, setAlgos] = useState([]);
  const [tabState, setTabState] = useState("all");
  const [asubs, setAsubs] = useState([]);
  const [filteredAlgos, setFilteredAlgos] = useState([]); // State for filtered algos
  const { setTotalInvestment } = useInvestment();

  const findStatusByAlgoID = (algoID, dataList) => {
    const foundObject = dataList.find((item) => item.AlgoID === algoID);
    return foundObject ? foundObject.Status : null;
  };

  const findAsubID = (algoID, dataList) => {
    const foundObject = dataList.find((item) => item.AlgoID === algoID);
    return foundObject ? foundObject.AsubID : null;
  };

  const calculateTotalInvestment = () => {
    return algos.reduce((total, algo) => {
      const asub = asubs.find(sub => sub.AlgoID === algo.AlgoID);
      if (asub) {
        return total + (algo.MarginRequired * asub.Qty); // Investment = MarginRequired * Qty
      }
      return total;
    }, 0);
  };

  useEffect(() => {
    const fetchAlgos = async () => {
      if (token) {
        const response = await getAlgos(token, {});
        setAlgos(response.data);
        const profileId = localStorage.getItem('profile_id');
        const response2 = await getAsubs(token, { profile_id: profileId });
        setAsubs(response2.data);
      }
    };
    fetchAlgos();
    
  }, [token]);

  useEffect(() => {
    // Calculate and update total investment whenever algos or asubs change
    const totalInvestment = calculateTotalInvestment();
    setTotalInvestment(totalInvestment);
  }, [algos, asubs, setTotalInvestment]); 

  useEffect(() => {
    // Filter algos whenever algos, asubs, or tabState changes
    setFilteredAlgos(filterAlgos());
  }, [algos, asubs, tabState]); // Dependencies: algos, asubs, tabState

  const handleAll = () => {
    setTabState("all");
  };

  const handleLive = () => {
    setTabState("live");
  };

  // Filter algos based on the selected tab
  const filterAlgos = () => {
    if (tabState === "live") {
      return algos.filter((algo) => findStatusByAlgoID(algo.AlgoID, asubs) === "Live"); // Only "Live" algos
    }
    return algos; // Show all algos when "All" tab is selected
  };

  return (
    <div className="algos-list">
      <div className='algos-header'>
        <h3>Algos</h3>
        <div className="algo-tabs">
          <button className={tabState === "all" ? "tab-all" : "tab-live"} onClick={handleAll}>All</button>
          <button className={tabState === "live" ? "tab-all" : "tab-live"} onClick={handleLive}>Live</button>
        </div>
      </div>

      {filteredAlgos.length === 0 && (
        <div className="no-algos">
          <p>{tabState === "live" ? "No live algos available." : "No algos available."}</p>
        </div>
      )}

      <div className="algo-cards">
        {filteredAlgos.map((algo, index) => {
          let stat = "";

          // Determine the status for the algo based on mode and connection status
          if (mode === "New") {
            stat = "Subscribe";
          } else if (mode === "KYC") {
            stat = "KYC";
          } else if (connect === "true") {
            stat = "Login";
          } else if (findStatusByAlgoID(algo.AlgoID, asubs) === null) {
            stat = "Open";
          } else if (findStatusByAlgoID(algo.AlgoID, asubs) === "Live") {
            stat = "Live";
          } else {
            stat = "Paused";
          }

          return (
            <AlgoCard 
              key={index} 
              ddpi={ddpi} 
              ddpiLink={ddpiLink} 
              name={algo.Name} 
              author={algo.Author} 
              capital={algo.MarginRequired} 
              initialStatus={stat} 
              risk={algo.Risk} 
              instruments={algo.Instruments} 
              tags={algo.Tags} 
              dd={algo.DD} 
              marginRequired={algo.MarginRequired} 
              entryTime={algo.EntryTime} 
              exitTime={algo.ExitTime} 
              description={algo.Description} 
              sd={algo.Sd} 
              algoId={algo.AlgoID} 
              asubId={findAsubID(algo.AlgoID, asubs)} 
            />
          );
        })}
      </div>
    </div>
  );
};

export default AlgosList;
