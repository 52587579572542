import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendMessageW, getPlan, createRawUser } from '../services/userService';

const AdminPage = () => {
  const navigate = useNavigate();
  const [number, setNumber] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileNumber2, setMobileNumber2] = useState(null);
  const [amount, setAmount] = useState(null);
  const [tenure, setTenure] = useState('');
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    const storedNumber = localStorage.getItem('number');
    setNumber(storedNumber);
    fetchPlans();
  }, []);


  const fetchPlans = async () => {
    try {
      // Replace with your actual API URL
      const token = localStorage.getItem('token');
      const response = await getPlan(token,{});
      console.log(response.data)
      setPlans(response.data); 
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const sendMessage = async () => {
    if (!mobileNumber) {
      alert("Please enter a mobile number");
      return;
    }

    try {
      const response = await sendMessageW({
        template: "onboarding1",
        number: mobileNumber,
      });

      if (response.status === 200 && response.success === 1) {
        alert(`✅ Message sent successfully to ${mobileNumber}!`);
      } else {
        alert("❌ There was an issue sending the message. Please try again.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("❌ An error occurred while sending the message. Please try again later.");
    }
  };

  const generatePaymentLink = async () => {
    if (!mobileNumber2) {
      alert("Please enter a mobile number");
      return;
    }
    if (!amount) {
      alert("Please enter an amount");
      return;
    }
    if (tenure == '') {
      alert("Please enter a tenure");
      return;
    }
    if (selectedPlan == '') {
      alert("Please enter a plan");
      return;
    }

    try {
      const response = await createRawUser({
        number: mobileNumber2,
      });

      if (response.status === 200 && response.success === 1) {
        setLink("https://app.tradeinsights.co.in/payment?profileId="+ response.data + "&amount="+btoa(amount)+ "&plan=" + selectedPlan + "&tenure=" + btoa(tenure))
      } else {
        alert("❌ There was an issue creating the user. Please try again.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("❌ An error occurred while sending the message. Please try again later.");
    }
  };

  return (
    <div style={styles.body}>
      {number === "9108303534" || number === "9557761700" ? (
        <div>
          <div style={{ padding: "20px", maxWidth: "400px", margin: "auto", textAlign: "center" }}>
            <h2>Send to WhatsApp</h2>
            <input
              type="text"
              value={mobileNumber || ""}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder="Enter mobile number"
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "10px",
                fontSize: "16px",
              }}
            />
            <button
              onClick={sendMessage}
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#25D366",
                color: "#fff",
                border: "none",
                cursor: "pointer",
                fontSize: "16px",
              }}
            >
              Send to WhatsApp
            </button>
          </div>
          <div style={styles.container}>
            <h2>Generate Payment Link</h2>
            <input
              type="text"
              value={mobileNumber2}
              onChange={(e) => setMobileNumber2(e.target.value)}
              placeholder="Enter Mobile Number"
              style={styles.input}
            />
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter Amount"
              style={styles.input}
            />
            <select
              value={tenure}
              onChange={(e) => setTenure(e.target.value)}
              style={styles.input}
            >
              <option value="" disabled>Select Tenure</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </select>

            <select
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              style={styles.input}
            >
              <option value="" disabled>Select Plan</option>
              {plans.map((plan) => (
                <option key={plan.PlanID} value={plan.PlanID}>
                  {plan.Name}
                </option>
              ))}
            </select>
            <button onClick={generatePaymentLink} style={styles.button}>
              Generate Payment Link
            </button>
            <div id="linkText" style={styles.link}>{link}</div>
          </div>
        </div>
      ) : (
        <div style={styles.messageBox}>
          <h1 style={styles.title}>You do not have access to this page!</h1>
        </div>
      )}
    </div>

    
  );
};

const styles = {
  body: {
    fontFamily: "'Arial', sans-serif",
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    background: "linear-gradient(135deg, #ff9a9e, #fad0c4)",
    color: "#333",
  },
  messageBox: {
    background: "#fff",
    borderRadius: "10px",
    padding: "20px 30px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    maxWidth: "400px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "15px",
    color: "#333",
  },
  container: {
    padding: '20px',
    maxWidth: '400px',
    margin: '50px auto',
    textAlign: 'center',
    border: '1px solid #ddd',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  button: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  link: {
    wordWrap: "break-word"
  }
};

export default AdminPage;
