import React, { createContext, useState, useContext } from 'react';

// Create a Context for totalInvestment
const InvestmentContext = createContext();

export const useInvestment = () => {
  return useContext(InvestmentContext);
};

export const InvestmentProvider = ({ children }) => {
  const [totalInvestment, setTotalInvestment] = useState(0);

  return (
    <InvestmentContext.Provider value={{ totalInvestment, setTotalInvestment }}>
      {children}
    </InvestmentContext.Provider>
  );
};
