import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import DashboardPage from './components/DashboardPage';
import PurchasePage from './components/PurchasePage';
import ScrollToTop from "./functions/scrollToTop"; 
import KYCCompletion from './components/KycCompletion';
import LoginCompletion from './components/LoginCompletion';
import AdminPage from './components/AdminPage';
import PaymentPage from './components/PaymentPage';
import { InvestmentProvider } from './context/InvestmentContext';

function App() {
  return (
    <InvestmentProvider>
      <Router>
        <ScrollToTop /> 
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/plans" element={<PurchasePage />} />
          <Route path="/kycCallback" element={<KYCCompletion />} />
          <Route path="/callback" element={<LoginCompletion />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/payment" element={<PaymentPage />} />
        </Routes>
      </Router>
    </InvestmentProvider>
  );
}

export default App;
