// src/components/InsightsCard.js
import React from 'react';
import '../../style/InsightsCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useInvestment } from '../../context/InvestmentContext';

const InsightsCard = ({ mode, userid, authorization, connect, investedi, current, profit, pnl }) => {

  const { totalInvestment } = useInvestment();
  // Ensure the values are numbers (convert if necessary)
  const parsedCurrent = isNaN(current) ? 0 : parseFloat(current);
  const parsedProfit = isNaN(profit) ? 0 : parseFloat(profit);
  const parsedInvested = isNaN(investedi) ? 0 : parseFloat(investedi);

  // Format the current amount, gain, and invested values
  const currentAmount = mode === "Live" && connect === "false" 
    ? (parsedCurrent >= 0 ? `₹${parsedCurrent.toFixed(2)}` : `-₹${Math.abs(parsedCurrent).toFixed(2)}`) 
    : '₹0';
  
  const gain = mode === "Live" && connect === "false" 
    ? (parsedProfit >= 0 ? `+₹${parsedProfit.toFixed(2)}` : `-₹${Math.abs(parsedProfit).toFixed(2)}`) 
    : '+₹0';
  
  const gainPercent = mode === "Live" && connect === "false" 
    ? (parsedInvested > 0 ? `${((parsedProfit / parsedInvested) * 100).toFixed(2)}%` : '0%') 
    : '0%';
  
  const invested = mode === "Live" && connect === "false" 
    ? (parsedInvested >= 0 ? `₹${parsedInvested.toFixed(2)}` : `-₹${Math.abs(parsedInvested).toFixed(2)}`) 
    : '₹0';

  // Determine if profit or current is negative to show the correct arrow icon
  const arrowIcon = parsedProfit >= 0 ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />;
  
  return (
    <div className="insights-card">
      <div className="card-content1">
        <h3>Current</h3>
        <p className="current-amount">₹{totalInvestment + pnl}</p>
        <p className={pnl >= 0 ? "gain":"loss"}>{pnl < 0 ? '-₹' + Math.abs(pnl): '₹'+pnl} <span className={pnl >= 0 ? "gain-icon":"loss-icon"}>{arrowIcon}</span> <span className={pnl >= 0 ? "gain-percentage":"loss-percentage"}>{totalInvestment > 0 ?((pnl/totalInvestment)*100).toFixed(2): 0}%</span></p>
      </div>
      <div className="card-content2">
        <h4>Invested</h4>
        <p className="invested-amount">₹{totalInvestment}</p>
      </div>
      {/* <div className="graph">
        <img src="path/to/graph.png" alt="Growth Graph" />
      </div> */}
    </div>
  );
};

export default InsightsCard;
