import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendOtp, verifyOtp, getOptionbaseDetails } from '../services/userService';
import '../style/LandingPage.css';
import logo from '../assets/logo.png';
import tick from '../assets/tick.png';
import logo2 from '../assets/logo2.png';
import tlogo from '../assets/tlogo.png';

const LandingPage = () => {
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState(false);
  const [transitionClass, setTransitionClass] = useState('visible');
  const [timer, setTimer] = useState(30); // Timer starts at 30 seconds
  

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    const kycStatus = localStorage.getItem('kyc_status');

    const loginTime = localStorage.getItem('loginTime');
    const loginDate = new Date(loginTime);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate - loginDate;

    // Step 5: Convert milliseconds to minutes
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);


    if (!!token && ((kycStatus === "false" && diffInMinutes < 30) || kycStatus === "true")) {
      navigate('/dashboard');
    }
  }, [navigate]);

  // Start the timer when OTP view is displayed
  useEffect(() => {
    let countdown;
    if (isOtpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(countdown); // Clear the interval when the timer reaches zero
    }
    return () => clearInterval(countdown); // Cleanup the interval on unmount
  }, [isOtpSent, timer]);

  const handleSendOtp = async (event) => {
    event.preventDefault();
    if (mobile.length !== 10) {
      setError(true);
      setMessage('Enter a valid mobile number');
      return;
    }
    setError(false);

    try {
      setTransitionClass('hidden');
      setTimeout(() => {
        setTransitionClass('visible');
        setIsOtpSent(true);
      }, 500);
      
      const response = await sendOtp({ number: mobile });
      setMessage(`OTP sent: ${response.message || 'Success'}`);

      // Start the transition effect
      
        
      setTimer(30); // Reset timer to 30 seconds
        
    } catch (error) {
      setMessage('Failed to send OTP. Please try again.');
    }
  };

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    try {
      const response = await verifyOtp({ number: mobile, otp });
      setMessage(`OTP verified: ${response.message || 'Success'}`);
      if (response.data) {
        
        const { token, profile_id, user_id, authorization, kyc_status, auth_expiry} = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('profile_id', profile_id);
        localStorage.setItem('number', mobile);
        const loginTime = new Date().toISOString();
        localStorage.setItem('loginTime', loginTime);
        localStorage.setItem('authorization', authorization);
        localStorage.setItem('user_id', user_id);
        localStorage.setItem('kyc_status', kyc_status);
        localStorage.setItem('auth_expiry', auth_expiry);
        localStorage.setItem('ddpi', false);
        // const response2 = await getOptionbaseDetails({ number: mobile });
        // if (response2.data && response2.data != null){
        //   const { UserID, Authorization, KycStatus, AccessExpiry} = response2.data;
        //   localStorage.setItem('authorization', Authorization);
        //   localStorage.setItem('user_id', UserID);
        //   localStorage.setItem('kyc_status', KycStatus);
        //   localStorage.setItem('auth_expiry', AccessExpiry);
        //   localStorage.setItem('number', mobile);
        //   const loginTime = new Date().toISOString();
        //   localStorage.setItem('loginTime', loginTime);
        // }else{
        //   localStorage.setItem('authorization', authorization);
        //   localStorage.setItem('user_id', user_id);
        //   localStorage.setItem('kyc_status', kyc_status);
        //   localStorage.setItem('auth_expiry', auth_expiry);
        //   localStorage.setItem('number', mobile);
        //   const loginTime = new Date().toISOString();
        //   localStorage.setItem('loginTime', loginTime);
        // }
        
        navigate('/dashboard');
      }
    } catch (error) {
      setMessage('Failed to verify OTP. Please try again.');
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await sendOtp({ number: mobile });
      setMessage(`OTP resent: ${response.message || 'Success'}`);
      setTimer(30); // Restart the timer
    } catch (error) {
      setMessage('Failed to resend OTP. Please try again.');
    }
  };

  const handleEditNumber = () => {
    setIsOtpSent(false); // Reset OTP view
    setOtp(''); // Clear OTP input
    setMessage(''); // Clear any messages
  };

  return (
    <div className="landing-container">
      <div className="login-section">
        <div className="logo-login">
          <img src={logo2} alt="Trade Insights Logo" className="logo-login-image" />
          <img src={tlogo} alt="Trade Insights" className="logo-login-image-title" />
        </div>
        <div className='login-main-section'>
          {isOtpSent ? (
            <div className={`otp-section ${transitionClass}`}>
              <h2>Verify OTP</h2>
              <label className="input-label">Enter OTP sent to {mobile}</label>
              <input
                type="text"
                placeholder="Enter OTP here"
                className="input-field"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              <div className='verify-add'>
                
                {/* Edit Number button */}
                <button className="edit-number-button" onClick={handleEditNumber}>
                  Edit Number
                </button>
                {timer > 0 ? (
                  <p className="timer-text">Resend OTP in {timer} seconds</p>
                ) : (
                  <button className="resend-button" onClick={handleResendOtp}>
                    Resend OTP
                  </button>
                )}
              </div>
              <button
                className="send-otp-button"
                onClick={handleVerifyOtp}
              >
                Verify OTP
              </button>
              
              
            </div>
          ) : (
            <div className={`phone-section ${transitionClass}`}>
              <h2>Log in</h2>
              <label className={`input-label ${isFocused || mobile ? 'focused' : ''}`}>
                Enter your Phone number
              </label>
              <input
                type="tel"
                placeholder="Enter number here"
                className={`input-field ${error ? 'error' : ''}`}
                value={mobile}
                maxLength="10"
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                  setMobile(value);
                  if (value.length === 10) setError(false);
                }}
                required
              />
              {error && <p className="message">{message}</p>}
              <button
                className="send-otp-button"
                onClick={handleSendOtp}
              >
                Send OTP
              </button>
            </div>
          )}
          <div className="sebi-info">
            <img alt="certified" src={tick} className='tick-icon'></img>
            <span>SEBI RA reg number: <span>INH000014492</span></span>
          </div>
          <p className="footer-text">
            © 2024 Trade Insights. All Rights Reserved. Made by traders
          </p>
          </div>
      </div>
      <div className="branding-section">
        <div className="branding-background">
          <img src={logo} alt="TI Logo" className="branding-logo" />
          <div className="branding-text">
            <span>Trade Insights</span>
            <img src={logo} alt="Small TI Logo" className="small-logo" />
          </div>
          <div className="footer-links">
            <a href="https://tradeinsights.co.in/#document" target='_blank'>License</a> <a href="https://tradeinsights.co.in/terms" target='_blank'>Terms of Use</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
