import React, { useState, useEffect } from "react";
import '../../style/AlgoDetail.css';
import algo1 from '../../assets/algo1.png';
import { useNavigate } from "react-router-dom";
import RunAlgo from "./RunAlgo";
import { createAsub, updateAsub } from "../../services/userService";




const AlgoDetail = ({onClose,updateStatus,ddpi,ddpiLink,name,author,capital,initialStatus,risk,instruments,tags,dd, marginRequired,entryTime,exitTime,description,sd, algoId, asubId }) => {
    const navigate = useNavigate();
    const [showPopup2, setShowPopup2] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [status, setStatus] = useState(initialStatus);
    const handleNavigate = () => {
      navigate("/plans"); // Navigate to the "/about" page
    };
    
    const handleNavigateKYC= () => {
      const number = localStorage.getItem('number');
      window.open("https://optionbase-kyc.enrichmoney.in/register?number="+number, "_blank");
    };
  
    const handleNavigateLogin= () => {
      window.open("https://orca.enrichmoney.in/partner/login?partner=OPTIONBASE", "_blank");
    };

    const handleTogglePopup2 = () => {
        setShowPopup2(!showPopup2);
    };

    useEffect(() => {
        if (status !== initialStatus) {
          setStatus(initialStatus);
        }
      }, [initialStatus]);

    const handlePause = async () => {
        if (processing) return;
        setProcessing(true);
        const token = localStorage.getItem("token");
        try {
          await updateAsub(token, { asub_id: asubId, status: "Paused" });
          setStatus("Paused");
          updateStatus("Paused");
        } catch (err) {
          console.error("Error pausing subscription:", err);
        } finally {
          setProcessing(false);
        }
      };
    
    const handleResume = async () => {
    if (processing) return; // Prevent overlapping calls
    setProcessing(true);
    const token = localStorage.getItem("token");
    try {
        await updateAsub(token, { asub_id: asubId, status: "Live" });
        setStatus("Live");
        updateStatus("Live");
    } catch (err) {
        console.error("Error resuming subscription:", err);
    } finally {
        setProcessing(false);
    }
    
    };

    const handleDdpi= () => {
      if(ddpiLink != null && ddpi != ""){
        window.open(ddpiLink, "_blank");
      }
    };

    let buttonClass = "subscribe"; // Default class
    if (ddpi === "false") {
      buttonClass = "subscribe";
    } else if (status === "Live") {
      buttonClass = "live2";
    } else if (status === "Open") {
      buttonClass = "open";
    } else if (status === "Paused") {
      buttonClass = "paused";
    }
    
    let buttonLabel = "Subscribe"; // Default label
  if (status === "Live") {
    if (ddpi == "false"){
      if(ddpiLink != null && ddpiLink != ""){
        buttonLabel = "Enable DDPI"
      }else{
        buttonLabel = "DDPI Processing"
      }
    }else{
      buttonLabel = "Pause";
    }
    
  } else if (status === "Open") {
    if (ddpi == "false"){
      if(ddpiLink != null && ddpiLink != ""){
        buttonLabel = "Enable DDPI"
      }else{
        buttonLabel = "DDPI Processing"
      }
    }else{
      buttonLabel = "Review & Run";
    }
    
  } else if (status === "Paused") {
    if (ddpi == "false"){
      if(ddpiLink != null && ddpiLink != ""){
        buttonLabel = "Enable DDPI"
      }else{
        buttonLabel = "DDPI Processing"
      }
    }else{
      buttonLabel = "Resume";
    }
  } else if (status === "KYC") {
    buttonLabel = "Complete KYC";
  } else if (status === "Login") {
    buttonLabel = "Login";
  }
  
    // Determine button label based on status
    

  return (
    <div>
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            
            <div className="popup-top">
                <img alt="algo" src={algo1}></img>
                <div className="popup-header">
                    <div className="popup-heading">
                        <h2>{name}</h2>
                        <div className="p-risk"><span>{risk} Risk</span></div>
                        <button className={`place-bid p-btn ${buttonClass}`} 
                        onClick={(e) => {
                            e.preventDefault(); // Prevent default behavior
                            e.stopPropagation();
              
                            if (status === "Live") {
                              if(ddpi === "false"){
                                handleDdpi();
                              }else{
                                handlePause();
                              }
                              setShowPopup2(false);
                            } else if (status === "Open") {
                              if(ddpi === "false"){
                                handleDdpi();
                              }else{
                                setShowPopup2(true)
                              }
                            } else if (status === "Paused") {
                              if(ddpi === "false"){
                                handleDdpi();
                              }else{
                                handleResume();
                              }
                             
                              setShowPopup2(false);
                            } else if (status === "KYC") {
                              handleNavigateKYC();
                              setShowPopup2(false);
                            } else if (status === "Login") {
                              handleNavigateLogin();
                              setShowPopup2(false);
                            } else {
                              handleNavigate();
                              setShowPopup2(false);
                            }
              
                            
                          }} 
                        >
                                {buttonLabel}
                        </button>
                    </div> 
                    <div className="p-author">By {author}</div>
                    <div className="p-details">
                        <div className="p-instruments p-hpoint">
                            <span>Underlying Index</span>
                            <span>{instruments[0]}</span>
                        </div>
                        <div className="p-dd p-hpoint">
                            <span>Drawdown</span>
                            <span>{dd}%</span>
                        </div>
                        <div className="p-mr p-hpoint">
                            <span>Minimum capital </span>
                            <span>₹{marginRequired}</span>
                        </div>
                        <div className="p-time p-hpoint">
                            <span>Entry-Exit Time</span>
                            <span>{entryTime} - {exitTime}</span>
                        </div>
                    </div>
                    <div className="p-sd">
                        {sd}
                    </div>
                    <div className="p-tags">
                    {tags.map((tag, index) => (
                        <span className="p-tag" key={index}>{tag}</span>
                    ))}
                    </div>
                </div>
            </div>
            <div className="popup-bottom"
            dangerouslySetInnerHTML={{ __html: description }}
            />
            
            </div>
        </div>
        {showPopup2 && (
        <RunAlgo
          onClose={() => {
            handleTogglePopup2();
          }}
          name={name}
          status={status}
          marginRequired={marginRequired}
          algoId={algoId}
          asubId={asubId}
        />
      )}
    </div>
  );
};

export default AlgoDetail;
