import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import InsightsCard from './dashboard/InsightsCard';
import TradeList from './dashboard/TradeList';
import AlgosList from './dashboard/AlgosList';
import './../style/DashboardPage.css';
import { getFunds, getPlanByProfileID, getPositions, getOptionbaseDetails, ddpiStatus, initDdpi, signDdpi, saveDdpi, getHoldings, getPnl } from '../services/userService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";



const DashboardPage = () => {
  const [kycStatus, setkycStatus] = useState(null);
  const [profileId, setProfileId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [authorization, setAuthorization] = useState(null);
  const [authExpiry, setAuthExpiry] = useState(null);
  const [loginTime, setLoginTime] = useState(null);
  const [mode, setMode] = useState(null);
  const [plan, setPlan] = useState(null);
  const [connect, setConnect] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [ddpi, setDdpi] = useState(null);
  const [ddpiLink, setDdpiLink] = useState(null)
  const [trades, setTrades] = useState([])
  const [holdings, setHoldings] = useState([])
  const [totalInvested, setTotalInvested] = useState(0);
  const [totalCurrent, setTotalCurrent] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [pnl, setPnl] = useState(0);



  const navigate = useNavigate();
  // useEffect(() => {
    
  //   fetchData()
  // }, [navigate]);

  const fetchData = async () => {
    const mobile = localStorage.getItem('number');
    const response2 = await getOptionbaseDetails({ number: mobile });
    if (response2.data && response2.data != null){
      const { UserID, Authorization, KycStatus, AccessExpiry, Ddpi} = response2.data;
      localStorage.setItem('authorization', Authorization);
      localStorage.setItem('user_id', UserID);
      localStorage.setItem('kyc_status', KycStatus);
      localStorage.setItem('auth_expiry', AccessExpiry);
      localStorage.setItem('ddpi', Ddpi);
    }else{
      
    }
    const token = localStorage.getItem('token');
    const kycStatus = localStorage.getItem('kyc_status');

    const loginTime = localStorage.getItem('loginTime');
    const loginDate = new Date(loginTime);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate - loginDate;

    // Step 5: Convert milliseconds to minutes
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);


    if (!token || (kycStatus === "false" && diffInMinutes > 30)) {
      navigate('/');
    }
  }

  // Fetch data from localStorage
  const fetchLocalStorageData = async () => {
    const kycStatus = localStorage.getItem('kyc_status');
    const profileId = localStorage.getItem('profile_id');
    const userId = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const authorization = localStorage.getItem('authorization');
    const authExpiry = localStorage.getItem('auth_expiry');
    const loginTime = localStorage.getItem('login_time');
    const ddpi = localStorage.getItem('ddpi');

    setkycStatus(kycStatus);
    setProfileId(profileId);
    setUserId(userId);
    setToken(token);
    setAuthorization(authorization);
    setAuthExpiry(authExpiry);
    setLoginTime(loginTime);
    setDdpi(ddpi)

    const response = await getPlanByProfileID(token,{ 'profile_id': profileId });
    setPlan(response.data);

    const authExpiryDate = new Date(authExpiry);
    const currentDate = new Date();
    const diffInMilliseconds = authExpiryDate - currentDate;


    

    

    if(authorization === "" || authorization === null || authExpiry === "" || authExpiry === null || diffInMilliseconds <= 600000){
      setConnect("true")
    }else{
      setConnect("false")
      try{
        const response = await getFunds(userId,authorization,{ });
        var availableBalance2 = 0;
        response.data.forEach((item) => {
          if (
              typeof item === 'object' &&
              item.hasOwnProperty('profile_name') &&
              item.profile_name === 'ALLLEVEL'
          ) {
              if (item.hasOwnProperty('cash')) {
                  availableBalance2 = item.cash;
                  
              }
              if (item.hasOwnProperty('margin_used')) {
                  availableBalance2 = availableBalance2 - item.margin_used;
              }
          }
        });
        const response2 = await getPositions(userId,authorization,{ });
        setTrades(response2.data)
        const response4 = await getHoldings(userId,authorization,{"product_type":"C"});
        setHoldings(response4.data)
        setAvailableBalance(availableBalance2)

        let totalInvestedValue = 0;
        let totalCurrentValue = 0;
        let totalProfitValue = 0;

        // Calculate invested and current value for each trade
        const updatedTrades = response2.data.map((trade) => {
          let investedValue = 0;
          let currentValue = 0;
          let profit = 0;

          // Live trades logic (net_quantity > 0)
          if (parseFloat(trade.net_quantity) > 0) {
            investedValue = parseFloat(trade.net_quantity) * parseFloat(trade.net_uploaded_price);
            currentValue = parseFloat(trade.net_quantity) * parseFloat(trade.ltp);
            profit = (currentValue - investedValue); // Profit calculation for live trades
          }
          console.log(investedValue)
          console.log(currentValue);
          console.log(profit)

          // Exited trades logic (day_sell_quantity > 0)
          if (parseFloat(trade.day_sell_quantity) > 0) {
            investedValue = investedValue + parseFloat(trade.day_sell_quantity) * parseFloat(trade.day_buy_average_price);
            currentValue = currentValue + parseFloat(trade.day_sell_quantity) * parseFloat(trade.day_buy_average_price) + parseFloat(trade.realized_pnl); // Realized profit for exited trades
            profit = profit + parseFloat(trade.realized_pnl); // Realized profit is directly taken from realized_pnl
          }
          console.log(investedValue)
          console.log(currentValue);
          console.log(profit)

          // Accumulate total values
          totalInvestedValue += investedValue;
          totalCurrentValue += currentValue;
          totalProfitValue += parseFloat(profit);

          
        });

        // Set state for updated trades and total values
        
        setTotalInvested(totalInvestedValue.toFixed(2));
        setTotalCurrent(totalCurrentValue.toFixed(2));
        setTotalProfit(totalProfitValue.toFixed(2));
      }catch(e){
        setConnect("true")
      }
    }

    if(kycStatus === "false" && response.data ==  null){
      setMode("New")
      console.log("a")
    }else if(kycStatus === "false" && response.data != null){
      setMode("KYC")
      console.log("b")
    }else{

      setMode("Live")
      console.log("c")
      if(ddpi === "false"){
        try{
          const response = await ddpiStatus(userId,authorization,{ });

          
          const dres =  response.data[0]['ddpi_status']
          if(response.data[0]['ddpi_status'] == undefined){
            const dres =  response.data[0]['stamp_paper_request_reference_id']
            if(dres != undefined && dres!= null && dres != ''){
              const response3 = await signDdpi(userId,authorization,{ "stamp_paper_request_reference_id":dres});
              const lres =  response3.data[0]['invitation_link']
              setDdpiLink(lres)
            }
          }

          if(dres === "Not Activated"){
            const response2 = await initDdpi(userId,authorization,{ });
            const dres =  response2.data[0]['stamp_paper_request_reference_id']
            if(dres != undefined && dres!= null && dres != ''){
              const response3 = await signDdpi(userId,authorization,{ "stamp_paper_request_reference_id":dres});
              const lres =  response3.data[0]['invitation_link']
              setDdpiLink(lres)
            }
          }else if(dres === "Under Processing"){
            const lres =  response.data[0]['ddpi_status_description']
            setDdpiLink(lres)
          }else if(dres === "E-Sign Completed"){
            setDdpiLink("")
          }else if(dres === "DDPI Activated"){
            setDdpi("true")
            const response4 = await saveDdpi({"user_id":userId, "ddpi":true});
            console.log(response4)
          }
        }catch(e){
          setDdpiLink("none")
        }
      }

      const responsep = await getPnl({"user_id":userId})
      setPnl(responsep.data)
    }
    
  }

  

  


  // Fetch localStorage data on component mount
  useEffect(() => {
    const initialize = async () => {
      await fetchData(); // Ensures fetchData runs first
      await fetchLocalStorageData(); // Runs only after fetchData completes
    };

    initialize();
  }, []);

  const handleNavigate = () => {
    navigate("/plans"); // Navigate to the "/about" page
  };
  const handleNavigateKYC= () => {
    const number = localStorage.getItem('number');
    window.open("https://optionbase-kyc.enrichmoney.in/register?number="+number, "_blank");
  };
  const handleNavigateConnect= () => {
    window.open("https://orca.enrichmoney.in/partner/login?partner=OPTIONBASE", "_blank");
  };
  const handleAddMoney= () => {
    window.open("https://orca.enrichmoney.in/partners/payin?partner=OPTIONBASE&segment=1&encodedUserDetails="+btoa(authorization), "_blank");
  };
  const handleDdpi= () => {
    if(ddpiLink != null && ddpi != ""){
      window.open(ddpiLink, "_blank");
    }
  };

  const handleRefresh=() => {
    window.location.reload();
  }
  
  return (
    <div className="dashboard">
      <Header mode={mode} userId={userId} authorization={authorization} connect={connect} availableBalance={availableBalance} />
      {mode === "New" && (
      <div className='top-notify'>
        <FontAwesomeIcon className='notify-icon' icon={faCircleInfo}></FontAwesomeIcon>
        <span className='notify-text'>Unlock the algos by subscribing at just ₹17/day</span>
        <button onClick={handleNavigate}>Subscribe</button>
      </div>
      )}
      {mode === "KYC" && (
      <div className='top-notify'>
        <FontAwesomeIcon className='notify-icon' icon={faCircleInfo}></FontAwesomeIcon>
        <span className='notify-text'>Complete your KYC with Returns broker to start your Algo journey</span>
        <button onClick={handleNavigateKYC}>Continue</button>
      </div>
      )}
      {mode === "Live" && (
        <div>
        {connect === "true" && (
          <div className='top-notify'>
            <FontAwesomeIcon className='notify-icon' icon={faCircleInfo}></FontAwesomeIcon>
            <span className='notify-text'>Connect the Returns broker for automated trades / funds addition</span>
            <div className='refresh-div'>
              <button onClick={handleNavigateConnect}>Connect</button>
              <FontAwesomeIcon className='refresh-icon' icon={faRefresh} onClick={handleRefresh}></FontAwesomeIcon>
            </div>
            
            
          </div>)
        }
        {connect === "false" && (
          <div>
            {availableBalance <= 1000 && (
              <div className='top-notify'>
                <FontAwesomeIcon className='notify-icon' icon={faCircleInfo}></FontAwesomeIcon>
                <span className='notify-text'>Low balance, Please add money to continue using your algo</span>
                <button onClick={handleAddMoney}>Add <br></br>money</button>
              </div>)
            }
          </div>
          )
        }
        {ddpi === "false" && ddpiLink != "none" && (
          <div className='top-notify'>
            <FontAwesomeIcon className='notify-icon' icon={faCircleInfo}></FontAwesomeIcon>
            <span className='notify-text'>Complete DDPI Request to enable automated selling of holdings.</span>
            <button onClick={handleDdpi}>{ddpiLink != null && ddpiLink != "" ? "Enable DDPI":"Processing"}</button>
          </div>
        )}
        </div>
      )}
      <div className="main-content">
        <InsightsCard mode={mode} userId={userId} authorization={authorization} connect={connect} investedi={totalInvested} current={totalCurrent} profit={totalProfit} pnl={pnl} />
        <TradeList mode={mode} userId={userId} authorization={authorization} connect={connect} trades={trades} holdings={holdings} />
      </div>
      <AlgosList mode={mode} userId={userId} authorization={authorization} token={token} connect={connect} ddpi={ddpi} ddpiLink={ddpiLink} />
      <footer className='dash-footer'>Investments in the market are subject to risk, and returns are not guaranteed. The 
      calculations above are based on current returns, which may not necessarily apply in the future.</footer>
    </div>
  );
};

export default DashboardPage;
