import React, { useState, useEffect , useRef} from 'react';
import '../../style/TradeList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const TradeList = ({ mode, userId, authorization, connect, trades, holdings }) => {
  const [selectedTab, setSelectedTab] = useState('Live'); // Manage selected tab
  const [liveHoldings, setLiveHoldings] = useState(holdings); 
  const [liveTrades, setLiveTrades] = useState([]);
  const [tradesLoaded, setTradesLoaded] = useState(false);
  const [socket, setSocket] = useState(null);
  const [holdingsLoaded, setHoldingsLoaded] = useState(false);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    setLiveHoldings(holdings);
    if (holdings.length > 0) {
        setHoldingsLoaded(true);
      }
  }, [holdings]);

  useEffect(() => {
    setLiveTrades(trades);
    if (trades.length > 0) {
        setTradesLoaded(true);
      }
  }, [trades]);

  // Filter trades based on selected mode (Live or Exited)
  const filteredTrades = trades.filter((trade) => {
    if (selectedTab === 'Live') {
        return true;
    //   return parseFloat(trade.net_quantity) > 0; // Only show live trades where net_quantity > 0
    } else if (selectedTab === 'Exited') {
        return true;
    //   return parseFloat(trade.day_sell_quantity) > 0; // Only show exited trades where day_sell_quantity > 0
    }
    return true; // Default case, return all trades
  });

  // Process a trade or holding
  const processTrade = (trade, isLive) => {
    let investedAmount = 0;
    let profit = 0;
    let quantity = 0;

    if (isLive) {
      // Live portion calculation
      investedAmount = parseFloat(trade.net_quantity) * parseFloat(trade.net_uploaded_price);
      profit = parseFloat(trade.net_quantity) * (parseFloat(trade.ltp) - parseFloat(trade.net_uploaded_price));
      quantity = parseFloat(trade.net_quantity)
    } else {
      // Exited portion calculation
      investedAmount = parseFloat(trade.day_sell_quantity) * parseFloat(trade.day_sell_average_price);
    //   profit = parseFloat(trade.realized_pnl); // Realized profit for exited trades
      quantity = parseFloat(trade.day_sell_quantity)
      const correspondingHolding = liveHoldings.find(
        (holding) => holding.exchange_trading_symbol[0].trading_symbol === trade.trading_symbol
      );
  
      if (correspondingHolding) {
        // If a corresponding holding is found, calculate profit as the difference between the price and holding price
        profit = (parseFloat(trade.net_uploaded_price) - parseFloat(correspondingHolding.uploaded_price)) * quantity;
      } else {
        // If no corresponding holding, use the realized profit from the trade itself
        profit = parseFloat(trade.realized_pnl);
      }
    }

    return {
      investedAmount: investedAmount.toFixed(2),
      profit: profit.toFixed(2),
      quantity: quantity,
    };
  };

  const generateTradeData = (trade) => {
    const tradeData = [];

    // If there's both live and exited portion, we create two entries
    if (parseFloat(trade.net_quantity) > 0) {
      const liveData = processTrade(trade, true); // Live portion
      tradeData.push({
        ...liveData,
        name: trade.trading_symbol,
        type: 'Live',
      });
    }

    if (parseFloat(trade.day_sell_quantity) > 0) {
      const exitedData = processTrade(trade, false); // Exited portion
      tradeData.push({
        ...exitedData,
        name: trade.trading_symbol,
        type: 'Exited',
      });
    }

    return tradeData;
  };

  const renderHoldings = () => {
    return liveHoldings.map((holding, index) => {
        var hq = "0";
      if(holding.un_settled_quantity == "0"){
        hq = holding.holding_quantity
      }else{
        hq = holding.un_settled_quantity
      }
      const investedAmount = (parseFloat(hq) * parseFloat(holding.uploaded_price)).toFixed(2);
      const profit = (
        parseFloat(hq) * (parseFloat(holding.ltp) - parseFloat(holding.uploaded_price))
      ).toFixed(2);

      return (
        <li key={index}>
          <div className="trade-flex">
            <div className="trade-details">
              <p className="trade-name">{holding.exchange_trading_symbol[0]["trading_symbol"]}</p>
              <p className="trade-invested">Invested ₹{investedAmount} <span>Qty {hq}</span></p>
            </div>
            <div className="trade-profit">
              <span className={profit >= 0 ? "profit-amount":"loss-amount"}>
                {parseFloat(profit) < 0 ? (
                  `-₹${Math.abs(parseFloat(profit)).toFixed(2)}` // For negative profit, show -₹ before the amount
                ) : (
                  `₹${parseFloat(profit).toFixed(2)}` // For positive profit, show ₹ before the amount
                )}
              </span>
              <span className={profit >= 0 ? "profit-percentage":"loss-percentage"}>
                <FontAwesomeIcon
                  className={`trade-caret ${parseFloat(profit) < 0 ? 'negative' : 'positive'}`} // Add 'negative' or 'positive' class based on profit sign
                  icon={parseFloat(profit) < 0 ? faCaretDown : faCaretUp}
                />
                {((parseFloat(profit) / parseFloat(investedAmount)) * 100).toFixed(2)}%
              </span>
            </div>
          </div>
        </li>
      );
    });
  };

  useEffect(() => {
    if (connect === 'false' && userId && authorization && holdingsLoaded) {
      // Initialize WebSocket connection
      const ws = new WebSocket('wss://orca-wss.enrichmoney.in/ws'); // Use your WebSocket URL

      ws.onopen = () => {
        // Authenticate the WebSocket connection
        ws.send(
          JSON.stringify({
            "t": "c",
            "uid": userId,
            "actid": userId,
            "susertoken": authorization,
            "source": "API"
          })
        );

        const heartbeatInterval = setInterval(() => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ "t": "h" }));
            }
        }, 30000); // 30 seconds

            // Clean up the heartbeat interval on component unmount
        return () => {
            clearInterval(heartbeatInterval); // Clear the heartbeat interval
        };

      };



      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(data)
  
        // Check for authentication acknowledgment (assuming 'auth' is the key in response)
        if (data && data['t'] === 'ck' && data['s'] === 'OK') {
          // After authentication, send subscription messages for each holding's token
          holdings.forEach((holding) => {
            console.log(holding)
            if (holding['exchange_trading_symbol'][0]['token_id']) {
              ws.send(
                JSON.stringify({
                  "t": "t",
                  "k": "NSE|" + holding['exchange_trading_symbol'][0]['token_id'] // Use the token from each holding
                })
              );
            }
          });
          trades.forEach((trade) => {
            console.log(trade)
            if (trade['token_id']) {
              ws.send(
                JSON.stringify({
                  "t": "t",
                  "k": "NSE|" + trade['token_id'] // Use the token from each trade
                })
              );
            }
          });
        }
        if (data && data.tk && data.lp) {
            const { tk, lp } = data;
            setLiveHoldings((prevHoldings) => {
                return prevHoldings.map((holding) => {
                    if (holding['exchange_trading_symbol'][0]['token_id'] === tk) {
                    const updatedLtp = lp;
                    

                    return {
                        ...holding,
                        ltp: updatedLtp, // Ensure ltp is updated
                    };
                    }
                    return holding; // Do not modify other holdings
                });
            });
            setLiveTrades((prevTrades) => {
                return prevTrades.map((trade) => {
                    if (trade['token_id'] === tk) {
                    const updatedLtp = lp;
                
                    return {
                        ...trade,
                        ltp: updatedLtp, // Ensure ltp is updated
                    };
                    }
                    return trade; // Do not modify other holdings
                });
            });
        }
    }

      // Clean up WebSocket on component unmount
      return () => {
        if (ws) {
          ws.close();
        }
      };
    }
  }, [connect, userId, authorization, holdings, holdingsLoaded]);

  return (
    <div className="trade-list">
      <h3>Trades</h3>
      <div className="tabs">
        <button
          className={selectedTab === 'Live' ? 'active' : ''}
          onClick={() => handleTabChange('Live')}
        >
          Positions
        </button>
        {/* <button
          className={selectedTab === 'Exited' ? 'active' : ''}
          onClick={() => handleTabChange('Exited')}
        >
          Exited Positions
        </button> */}
        <button
          className={selectedTab === 'Holdings' ? 'active' : ''}
          onClick={() => handleTabChange('Holdings')}
        >
          Holdings
        </button>
      </div>
      {connect === 'false' ? (
        <ul>
          {selectedTab === 'Holdings'
            ? renderHoldings()
            : liveTrades.map((trade, index) => {
                const tradeData = generateTradeData(trade);
                console.log(tradeData)

                return tradeData.map((data, dataIndex) => {
                  // Only show the data corresponding to the selected tab (Live or Exited)
                  if (selectedTab === "Live" || selectedTab === "Exited") {
                    return (
                      <li key={dataIndex}>
                        <div className="trade-flex">
                          <div className="trade-details">
                            <p className="trade-name">{data.name} <span>{data.type == "Exited"?"Exited":""}</span></p>
                            <p className="trade-invested">{data.type == "Exited" ? 'Qty ' + data.quantity: 'Invested ₹' + data.investedAmount + ' Qty ' + data.quantity}</p>
                          </div>
                          <div className="trade-profit">
                            <span className={data.profit >=0 ? "profit-amount":"loss-amount"}>
                              {parseFloat(data.profit) < 0 ? (
                                `-₹${Math.abs(parseFloat(data.profit)).toFixed(2)}`
                              ) : (
                                `₹${parseFloat(data.profit).toFixed(2)}`
                              )}
                            </span>
                            <span className={data.profit >=0 ? "profit-percentage":"loss-percentage"}>
                              <FontAwesomeIcon
                                className={`trade-caret ${
                                  parseFloat(data.profit) < 0 ? 'negative' : 'positive'
                                }`}
                                icon={parseFloat(data.profit) < 0 ? faCaretDown : faCaretUp}
                              />
                              {((parseFloat(data.profit) / parseFloat(data.investedAmount)) * 100).toFixed(2)}%
                            </span>
                          </div>
                        </div>
                      </li>
                    );
                  }
                  return null; // Don't render if the data is not for the selected tab
                });
              })}
        </ul>
      ) : (
        <div className="trade-holder">Your live trades will show here</div>
      )}
    </div>
  );
};

export default TradeList;
