import React, { useState , useEffect} from "react";
import '../../style/RunAlgo.css';
import { useNavigate } from "react-router-dom";
import { getFunds } from '../../services/userService';
import { createAsub, updateAsub } from "../../services/userService";





const RunAlgo = ({onClose,name,status, marginRequired,algoId,asubId}) => {
  const [qty, setQty] = useState(1);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [processing, setProcessing] = useState(false); 
  const [sufficient, setSufficient] = useState(false); 

  const handleChange = (event) => {
    const newValue = event.target.value;

    // Ensure that the value is a positive integer or empty (allow clearing the input)
    if (newValue === '' || /^[+]?\d+$/.test(newValue)) {
      // If the value is empty or a positive integer, update the state
      if (newValue === '' || parseInt(newValue) >= 1) {
        setQty(newValue);
      }
    }
  };

  const fetchData = async () => {
    const userId = localStorage.getItem('user_id');
    const authorization = localStorage.getItem('authorization');
    const response = await getFunds(userId,authorization,{ });
    var availableBalance2 = 0;
    response.data.forEach((item) => {
    if (
        typeof item === 'object' &&
        item.hasOwnProperty('profile_name') &&
        item.profile_name === 'ALLLEVEL'
    ) {
        if (item.hasOwnProperty('cash')) {
            availableBalance2 = item.cash;
            
        }
        if (item.hasOwnProperty('margin_used')) {
            availableBalance2 = availableBalance2 - item.margin_used;
        }
    }
    });
    setAvailableBalance(availableBalance2)
    if(availableBalance2 >= marginRequired*parseInt(qty)){
        setSufficient(true)
    }else{
        setSufficient(false)
    }
    
  }

  

  // Fetch localStorage data on component mount
  useEffect(() => {
    const initialize = async () => {
      await fetchData();
    }

    initialize();
  }, []);

  const handleRun = async () => {
    if(availableBalance >= marginRequired*parseInt(qty)){
        if (processing) return; // Prevent overlapping calls
        setProcessing(true);
        const token = localStorage.getItem("token");
        const profile_id = localStorage.getItem("profile_id");
        try {
        await createAsub(token, {profile_id:profile_id, algo_id: algoId, status: "Live", qty:parseInt(qty) });
        } catch (err) {
        console.error("Error resuming subscription:", err);
        } finally {
        setProcessing(false);
        alert("Algo Running Successfully with "+ qty + "x Multiple")
        window.location.reload();
        
        }
    }else{
        alert("Add funds to continue.")
    }
    
  };

  const handleFunds= () => {
    const authorization = localStorage.getItem("authorization");
    window.open("https://orca.enrichmoney.in/partners/payin?partner=OPTIONBASE&segment=1&encodedUserDetails="+btoa(authorization), "_blank");
  };


  const navigate = useNavigate();
  return (
    <div>
        <div className="run-overlay" onClick={onClose}>
            <div className="run-content" onClick={(e) => e.stopPropagation()}>
                <h2>{name}</h2>
                <div className="quantity-div">
                    <span>Quantity</span>
                    <input type="number" value={qty} onChange={handleChange} min={1} step="1"></input>
                </div>
                <div className="quantity-capital">
                    <span>Min Balance Req: ₹{marginRequired*parseInt(qty)}</span>
                    <span>Avl Balance : ₹{availableBalance}</span>
                </div>
                <div className="quantity-actions">
                    <button onClick={onClose}>Cancel</button>
                    {sufficient &&
                    <button onClick={handleRun}>Confirm & Run</button>
                    }
                    {!sufficient &&
                    <button onClick={handleFunds}>Add Funds</button>
                    }
                
                </div>
            
            </div>
        </div>
    </div>
  );
};

export default RunAlgo;
